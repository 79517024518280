<template>
  <!-- 散户持仓列表 -->
  <div class="container">
    <template v-if="isMobile == 0">
    <div class="header">
      <i class="iconfont2 icon-shaixuan"></i>
      <span>{{ $t("tools.holderList.name") }}</span>
    </div>
    <div class="content">
      <div class="hd">
        <span class="address">{{ $t("tools.positionAddress") }}</span>
        <span class="quantity">{{ $t("tools.positionQuantity") }}</span>
        <span class="percentage">{{ $t("tools.positionPercentage") }}</span>
        <!-- <span class="time">{{ $t("tools.lastUpdated") }}</span> -->
        <span class="remark">{{ $t("tools.remark") }}</span>
        <span class="operation">{{ $t("common.operation") }}</span>
      </div>
      <div class="bd">
        <template v-if="holderList.length">
          <div
            class="list"
            v-infinite-scroll="load"
            :infinite-scroll-distance="10"
            :infinite-scroll-delay="400"
            :infinite-scroll-disabled="load_disabled"
          >
            <div class="item" v-for="(item, index) in holderList" :key="index">
              <span class="address">
                <el-tooltip
                  effect="dark"
                  :content="$t('subManage.copyAddress')"
                  placement="top"
                  :enterable="false"
                >
                  <i
                    class="iconfont2 icon-jilu1 copyBtn"
                    @click="handleCopy(item.wallet)"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  :content="item.wallet"
                  placement="top"
                  :enterable="false"
                >
                  <span class="copyBtn" @click="handleCopy(item.wallet)">
                    {{ item.wallet }}
                  </span>
                </el-tooltip>
              </span>
              <span class="quantity"> {{ item.balance }} {{ yenUnit1 }} </span>
              <span class="percentage"> {{ item.percent*100 }}% </span>
              <!-- <span class="time">{{ item.update_time }}</span> -->
              <span class="remark">
                <span>{{ remarkData[item.wallet]||'' }}</span>
                <div class="g_btn more" v-if="remarkData[item.wallet]" @click="openRemark('readOnly', item)">
                  {{ $t("common.more") }}
                </div>
              </span>
              <span class="operation">
                <div class="g_btn btn submit" @click="openRemark('edit', item)">
                  {{ $t("common.edit") }}
                </div>
              </span>
            </div>
          </div>
        </template>
        <el-empty v-else :image="emptyImg" :image-size="120" />
      </div>
    </div>
    </template>
    <template v-else-if="isMobile == 1">
      <div class="content_phone">
        <template v-if="holderList.length">
          <div
            class="list"
            v-infinite-scroll="load"
            :infinite-scroll-distance="10"
            :infinite-scroll-delay="400"
            :infinite-scroll-disabled="load_disabled"
          >
            <div
              class="item"
              v-for="(item, index) in holderList"
              :key="index"
            >
              <div>
                <div class="label">{{ $t("tools.positionAddress") }}</div>
                <div class="value">
                  <span class="copyBtn" @click="handleCopy(item.wallet)">
                    {{ item.wallet }}
                  </span>
                  <i
                    class="iconfont2 icon-jilu1 copyBtn"
                    @click="handleCopy(item.wallet)"
                  ></i>
                </div>
              </div>
              <div>
                <div class="label">{{ $t("tools.positionQuantity") }}</div>
                <div class="value">
                    {{ item.balance }} {{ yenUnit1 }} 
                </div>
              </div>
              <div>
                <div class="label">{{ $t("tools.positionPercentage") }}</div>
                <div class="value">
                  {{ item.percent*100 }}%
                </div>
              </div>
              <!-- <div>
                <div class="label">{{ $t("tools.lastUpdated") }}</div>
                <div class="value">
                  {{ item.update_time }}
                </div>
              </div> -->
              <div>
                <div class="label">{{ $t("tools.remark") }}</div>
                <div class="value">
                  <span>{{ remarkData[item.wallet]||'' }}</span>
                  <div class="more" v-if="remarkData[item.wallet]" @click="openRemark('readOnly', item)">
                    {{ $t("common.more") }}
                  </div>
                </div>
              </div>
              <div class="operation">
                <div class="g_btn btn submit" @click="openRemark('edit', item)">
                  {{ $t("common.edit") }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-empty v-else :image="emptyImg" :image-size="120" />
      </div>
    </template>

    <el-dialog
      :title="isEdit ? $t('tools.editRemark') : $t('tools.viewRemark')"
      :visible.sync="showRemark"
      @close="showRemark = false"
    >
      <div class="dialogFormBox">
        <div class="textarea">
          <el-input
						:readonly="!isEdit"
            spellcheck="false"
            type="textarea"
            :rows="6"
            :placeholder="$t('common.input')"
            v-model="remark"
          >
          </el-input>
        </div>
        <div class="g_dialogBtns" v-if="isEdit">
          <div class="g_btn btn" @click="showRemark = false">
            {{ $t("common.back") }}
          </div>
          <div class="g_btn btn submit" @click="editRemark">
            {{ $t("common.confirm2") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
import { 
  getHolderListSan,
  getSanHolderRemark,
  setSanHolderRemark,
 } from "@/api/markets";
export default {
  data() {
    return {
      emptyImg: require("@/assets/images/loading.png"),

      // 散户持仓列表
      holderList: [],
      web: "",
      yenUnit1: "",
      yenUnit2: "",
      page: 1,
      load_disabled: false,
      cursor: "",
      remarkData: {},

      // 弹窗
      isEdit: false,
      showRemark: false,
      remark: "",
    };
  },
  computed: {
    ...mapGetters(["account", "isMobile"]),
  },
  mounted() {
    this.web = this.$route.query.web;
    this.yenUnit1 = this.$route.query.yenUnit1;
    this.yenUnit2 = this.$route.query.yenUnit2;
    this.getHolderList();
    this.getSanHolderRemark();
  },
  methods: {
    // 打开备注弹窗
    openRemark(type, item) {
			this.remark = this.remarkData[item.wallet] || "";
      this.wallet = item.wallet;
      this.isEdit = type == "edit";
      this.showRemark = true;
    },
    // 编辑备注
    async editRemark() {
			if(this.isEdit){
				// 编辑备注
				const loading = Loading.service({
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
					customClass: "apploading",
				});
				try {
					await setSanHolderRemark({
						account: this.account,
						web: this.web,
						currency: this.yenUnit1 + "_" + this.yenUnit2,
            address: this.wallet,
						remark: this.remark,
					});
          this.getSanHolderRemark();
					loading.close();
				} catch (error) {
					loading.close();
				}
			}
      this.showRemark = false;
    },
    // 加载更多
    load() {
      // console.log("get-load");
      this.page++;
      this.getHolderList();
    },
    // 获取散户持仓钱包备注列表
    getSanHolderRemark() {
      getSanHolderRemark({
        account: this.account,
        web: this.web,
        currency: this.yenUnit1 + "_" + this.yenUnit2,
      }).then((res) => {
        this.remarkData = res? res : {};
      }).catch((err) => {
        this.remarkData = {};
      });
    },
    // 获取散户持仓列表
    getHolderList() {
      const loading = Loading.service({
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      getHolderListSan({
        account: this.account,
        web: this.web,
        currency: this.yenUnit1 + "_" + this.yenUnit2,
        page: this.page,
        cursor: this.cursor,
      })
        .then((res) => {
          loading.close();
          if (res && res.holder_list && res.holder_list.length > 0) {
            this.cursor = res.cursor;
            // this.holderList = res.holder_list;
            this.holderList.push(...res.holder_list);
            // // 轮询
            // if (res.holder_list.length == 1000) {
            //   this.page++;
            //   this.getHolderList();
            // }
          } else {
            this.load_disabled = true;
            this.cursor = "";
            this.holderList = [];
          }
        })
        .catch((err) => {
          this.cursor = "";
          this.holderList = [];
          loading.close();
        });
    },

    // 复制地址
    handleCopy(code) {
      let clipboard = new Clipboard(".copyBtn", {
        text: () => {
          return code;
        },
      });
      clipboard.on("success", () => {
        this.$message({
          message: this.$t("tip.copysuccess"),
          type: "success",
          center: true,
          customClass: "shotMsg",
        });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message({
          message: this.$t("tip.copyerr"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
        clipboard.destroy();
      });
    },
  },
};
</script>
<style scoped="scoped" lang="stylus">
@import 'holderList.styl';
</style>